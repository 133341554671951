import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: 94,
    background: '#0319A3',
    display: 'grid',
    gap: 10,
    '@media screen and (min-width:576px)': {
      gridTemplateColumns: '1fr 1fr ',
    },
    '@media screen and (max-width:992px)': {
      gridTemplateColumns: '1fr ',
    },
  },

  title: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '67.1px;',
    letterSpacing: '-3px',
    color: '#00DD9E',
    '@media screen and (min-width:576px)': {
      fontSize: 61,
    },
    '@media screen and (max-width:992px)': {
      fontSize: 54,
    },
  },

  logoEgreenDark: {
    width: 245,
    height: 81,
    marginTop: 25,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (min-width:576px)': {
      height: '100%',
      justifyContent: 'space-between',
      textAlign: 'left',
      width: 548.5,
      paddingBottom: 50,
    },
    '@media screen and (max-width:992px)': {
      width: 'auto',
      textAlign: 'center',
      alignItems: 'center',
      height: '100%',
      paddingLeft: 0,
      paddingBottom: 0,
    },
  },

  subtitle: {
    fontSize: 47,
    letterSpacing: '-3px',
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '52px ',
    marginBottom: 29,
    color: '#F4F4F1',
    '@media screen and (min-width:576px)': {
      fontSize: 47,
      textAlign: 'left',
    },
    '@media screen and (max-width:992px)': {
      fontSize: 37,
      textAlign: 'center',
    },
  },
  //izquierda
  gridItemStart: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: 10,
    '@media screen and (max-width:992px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },

  gridItemEnd: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: 10,
    '@media screen and (max-width:992px)': {
      gridTemplateColumns: '1fr',
    },
  },
  end: {
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 0,
    fontFamily: 'Montserrat',
    color: '#F4F4F1',
    paddingBottom: 3,
    textAlign: 'left',
    '@media screen and (min-width:576px)': {
      margin: '150px 0px 50px 0px',
      textAlign: 'left',
    },
    '@media screen and (max-width:992px)': {
      margin: '0px 0px 20px 0px',
      textAlign: 'center',
    },
  },

  opacity: {
    opacity: 0.4,
  },

  start: {
    fontFamily: 'MontserratRegular',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 0,
    opacity: 0.7,
    color: '#F4F4F1',
    '@media screen and (min-width:576px)': {
      textAlign: 'left',
    },
    '@media screen and (max-width:992px)': {
      textAlign: 'left',
    },
  },

  network: {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    '@media screen and (min-width:576px)': {
      justifyContent: 'flex-start',
    },
    '@media screen and (max-width:992px)': {
      justifyContent: 'center',
    },
  },
  networkIcon: {
    marginRight: 12,
  },
  icon: {
    width: '30px',
    height: '30px',
    color: '#FFFFFF',
    marginRight: 12,
  },

  customLink: {
    textDecoration: 'none',
  },

  fontLink: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '190%',
    color: '#F4F4F1',
    '&:hover': {
      color: '#00DD9E',
      textDecoration: 'none',
    },
  },
  fontLinkLigth: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '190%',
    color: '#F4F4F1',
    opacity: '0.4',
  },
  '&:hover': {
    color: '#00DD9E',
    textDecoration: 'none',
  },
}));

export default useStyles;
